<template>
  <div>
    <Header class="" />
    <section
      v-if="plans"
      id="pricing-plan"
    >
      <!-- title text and switch button -->
      <br></br>
      <br></br>
      <b-card>
        <h3>{{ $t('pricing.header') }} </h3>
      </b-card>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <!-- <h6 class="mr-1 mb-0">
            Monthly
          </h6> -->
        <!-- <b-form-checkbox
            id="priceSwitch"
            v-model="status"
            name="price-switch"
            value="annually"
            unchecked-value="monthly"
            switch
            @input="tooglePlan"
          /> -->
        <!-- <h6 class="ml-50 mb-0">
            Annually
          </h6> -->
      </div>
      </b-card>
      <!--/ title text and switch button -->

      <!-- pricing plan cards -->
      <b-row class="pricing-card">
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <b-row>
            <b-col

              v-for="plan in plans"
              v-if="user.userType === plan.type || user.userType === undefined"
              :key="plan._id"
              md="4"
            >
              <b-card
                class="pt-0 mt-0 pricing-card-body"
                align="center"
              >
                <!-- img -->
                <!--/ img -->
                <h2 v-if="$store.state.locale.locale ==='en'">
                  {{ plan.name }} <b-img
                    v-if="plan.image"
                    :src="plan.image"
                    height="40%"
                    width="40%"
                    class="mb-1 mt-1"
                    alt="basic svg img"
                  />
                </h2>
                <h2 v-if="$store.state.locale.locale ==='ar'">
                  {{ plan.arName }}  <b-img
                    v-if="plan.image"
                    :src="plan.image"
                    height="40%"
                    width="40%"
                    class="mb-1 mt-1"
                    alt="basic svg img"
                  />

                </h2>
                <!-- <b-card-text>
                  {{ pricing.basicPlan.subtitle }}
                </b-card-text> -->

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">{{ plan.currency }}</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ plan.price }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('pricing.year') }}</sub>
                  </div>
                  <!-- <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} / year</small> -->
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-card-text
                    v-if="$store.state.locale.locale ==='en'"
                    v-html="plan.content"
                  />
                  <b-card-text
                    v-if="$store.state.locale.locale ==='ar'"
                    v-html="plan.arContent"
                  />
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->
                <div class="plan-btn">
                  <b-button
                    v-if="planId.plan === plan._id"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-auto align-self-end btn "
                    variant="outline-success"
                    @click="handelSelectedPlan(plan._id)"
                  >
                    {{ isHaveTrial ?$t('pricing.trialPlan'): $t('pricing.plan')}}
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-auto align-self-end btn"
                    variant="primary"
                    @click="handelSelectedPlan(plan._id)"
                  >
                    {{ $t('pricing.upgrade') }}
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->

      <!-- pricing free trial -->
      <!--/ pricing free trial -->

      <!-- pricing faq -->
    <!--/ pricing faq -->
    </section>
    <Footer class="company-form-footer" />
  </div>

</template>

<script>
import {
  BRow, BCol, BCard, BImg, BListGroup, BCardText,
  BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import moment from 'moment'
import Header from '@/layouts/landpage/Header.vue'
import Footer from '@/layouts/landpage/Footer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable global-require */
export default {
  components: {
    BButton,
    Header,
    Footer,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BBadge,
    BImg,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      boxOne: '',
      boxTwo: '',
    }
  },
  computed: {
    ...mapGetters({
      plans: 'profile/getAllPlans',
      user: 'auth/user',
      isAuth: 'auth/isAuth',
      getTrialCount: 'auth/getTrialCount',
      getTrialExpirationDate: 'auth/getTrialExpirationDate',
    }),
    isHaveTrial() {
      // Date.parse(this.getTrialExpirationDate) > new Date()
      if (this.getTrialCount === 1 && (this.getTrialExpirationDate === '' || this.getTrialExpirationDate === null)) return true
      return false
    },
    planId() {
      return this.user.subscriptions.find(o => o.isActive === true) || {}
    },
  },
  created() {
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' })
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
    this.fetchPlans()
  },
  methods: {
    ...mapActions({
      fetchPlans: 'profile/retreivePlans',
      registerInPlan: 'profile/registerInPlan',
      registerInTrialPlan: 'profile/registerInTrialPlan',
      dispatchAttempt: 'auth/attempt',
    }),
    trialCheckout(id) {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm(this.$t('trial.desc'), {
          title: this.$t('trial.header'),
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: this.$t('pricing.ok'),
          cancelTitle: this.$t('pricing.cancel'),
        })
        .then(value => {
          if (value) {
            const formData = new FormData()
            formData.append('planId', id)
            formData.append('userId', this.user._id)
            this.registerInTrialPlan(formData)
              .then(response => {
                store.commit('auth/SET_ACCESS_TOKEN', response.data.access_token)
                this.dispatchAttempt(response.data.access_token)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    takePlan(id) {
      // && (this.user.role.name === 'User')
      if (this.isAuth && (this.user.subscriptions.length === 1)) {
        this.boxOne = ''
        this.$bvModal
          .msgBoxConfirm(`${this.$t('pricing.modelSubH1')} -${this.$store.state.locale.locale === 'en' ? this.user.subscriptions[0].planName : this.user.subscriptions[0].arPlanName} - ${this.$t('pricing.modelSubH2')} ${moment(String(this.user.subscriptions[0].endDate)).format('LL')} ${this.$t('pricing.modelSubH3')}`, {
            title: this.$t('pricing.modelHeader'),
            size: 'sm',
            okVariant: 'primary',
            cancelVariant: 'outline-secondary',
            okTitle: this.$t('pricing.ok'),
            cancelTitle: this.$t('pricing.cancel'),
          })
          .then(value => {
            if (value) {
              const formData = new FormData()
              formData.append('planId', id)
              formData.append('userId', this.user._id)
              formData.append('subId', this.user.subscriptions[0]._id ? this.user.subscriptions[0]._id : null)
              formData.append('stripeId', this.user.subscriptions[0].subscriptionId)
              this.registerInPlan(formData)
                .then(response => {
                  window.location.href = response.data.url
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.response.data.error.message,
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      } else if (this.isAuth && (this.user.subscriptions.length === 0)) {
        const formData = new FormData()
        formData.append('planId', id)
        formData.append('userId', this.user._id)
        this.registerInPlan(formData)
          .then(response => {
            window.location.href = response.data.url
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.error.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('pricing.unRegisteredMSG'),
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$router.push({ path: '/login' })
      }
    },
    handelSelectedPlan(id) {
      if (this.isAuth && (this.user.subscriptions.length === 0) && this.isHaveTrial) {
        this.trialCheckout(id)
      } else if (this.isAuth && Date.parse(this.getTrialExpirationDate) > Date.parse(new Date())) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.$t('trial.warningDesc')} : ${this.getTrialExpirationDate}`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        this.takePlan(id)
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
[dir] .list-group.list-group-circle .list-group-item:after {
  background-image: none;
}
.pricing-card-body .card-body {
  min-height: 644px;
}

.plan-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 35px;
}
</style>
